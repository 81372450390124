import { Component, OnInit } from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import {WebSocketService} from '../../../../services/web-socket.service';

// Interface
interface notification {
  counter: number;
  customers: number;
  file_requests: number;
  courier_requests: number;
  transactions:number;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;

  /**
   *
   * @param {NotificationsService} _notificationsService
   * @param webSocketService
   */
  constructor(private _notificationsService: NotificationsService, private webSocketService: WebSocketService) {

  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._notificationsService.onNotificationsChange.subscribe(res => {
      this.notifications = res;
    });

    this.webSocketService.notifications.bind("notifications", data => {
      this._notificationsService.getNotifications()
    })
  }

}
