<!-- app-content-header start -->
<ng-container *ngIf="contentHeader">
  <div class="content-header row">
    <div class="content-header-left col-md-9 col-12 mb-2">
      <div class="row breadcrumbs-top">
        <div class="col-12 d-flex">
          <h2 class="content-header-title float-left mb-0">
            {{ contentHeader.headerTitle }}
          </h2>
          <!-- app-breadcrumb component -->
          <app-breadcrumb [breadcrumb]="contentHeader.breadcrumb"></app-breadcrumb>
        </div>
      </div>
    </div>
    <ng-container *ngIf="contentHeader.actionButton">
      <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
        <div class="form-group breadcrum-right">
          <div ngbDropdown>
            <button
              ngbDropdownToggle
              id="dropdownSettings"
              class="btn-icon btn btn-primary btn-round btn-sm"
              type="button"
              rippleEffect
            >
              <span [data-feather]="'grid'"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownSettings">
              <a ngbDropdownItem [routerLink]="['/apps/todo']"
                ><span [data-feather]="'check-square'" [class]="'mr-50'"></span> Todo</a
              >
              <a ngbDropdownItem [routerLink]="['/apps/chat']"
                ><span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chat</a
              >
              <a ngbDropdownItem [routerLink]="['/apps/email']"
                ><span [data-feather]="'mail'" [class]="'mr-50'"></span> Email</a
              >
              <a ngbDropdownItem [routerLink]="['/apps/calendar']"
                ><span [data-feather]="'calendar'" [class]="'mr-50'"></span> Calendar</a
              >
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <ngb-alert *ngIf="message"  [type]="'success'" [dismissible]="true">
    <h4 class="alert-heading">Success</h4>
    <div class="alert-body">
      {{message}}
    </div>
  </ngb-alert>
  <ngb-alert *ngIf="error" [type]="'danger'" [dismissible]="true">
    <h4 class="alert-heading">Failure</h4>
    <div class="alert-body">
      {{error}}
    </div>
  </ngb-alert>

  <!-- ngb-toast component -->
  <ngb-toast
          *ngFor="let toast of toastService.toasts"
          [class]="toast.classname"
          [autohide]="toast.autohide"
          [delay]="toast.delay || 5000"
          (hide)="toastService.remove(toast)">
    <!-- toast header -->
    <ng-template ngbToastHeader>
      <img src="assets/images/logo/logo.png" class="mr-2" height="18" width="25" alt="Toast image" />
      <strong class="mr-auto toast-title">Vuexy Admin</strong>
      <small class="text-muted toast-time ml-5 mr-1 pl-3">11 mins ago</small>
    </ng-template>
    <!--/ toast header -->

    <!-- toast body -->
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>
    <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    <!--/ toast body -->
  </ngb-toast>
  <!--/ ngb-toast component -->
</ng-container>
<!-- app-content-header end -->


