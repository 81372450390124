import {Component, OnInit, Input, TemplateRef} from '@angular/core';
import {ToastService} from '../../../main/components/toasts/toasts.service';

// ContentHeader component interface
export interface ContentHeader {
  headerTitle: string;
  actionButton: false;
  breadcrumb?: {
    type?: string;
    links?: Array<{
      name?: string;
      isLink?: boolean;
      link?: string;
    }>;
  };
}

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html'
})
export class ContentHeaderComponent implements OnInit {
  // input variable
  @Input() contentHeader: ContentHeader;
  @Input() error = '';
  @Input() message = '';
  public toastStyle: object = {};
  constructor(public toastService: ToastService) {}

  ngOnInit() {
    this.toastStyle = { left: 'unset', right: 0 };
  }

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }

}
