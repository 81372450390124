import { CoreMenu } from '@core/types';

export const menu: CoreMenu[] = [

  {
    id: 'dashboard',
    title: 'Orders',
    type: 'item',
    icon: 'layers',
    url: 'dashboard'
  },
  {
    id: 'dn',
    title: 'Delivery Notes',
    type: 'item',
    icon: 'file',
    url: 'delivery-notes/list'
  },
  {
    id: 'account',
    title: 'Account',
    type: 'item',
    icon: 'user',
    url: 'account'
  }
];
