export class SearchFakeData {
  public static search = [
    {
      groupTitle: 'Pages',
      searchLimit: 4,
      bookmarkLimit: 6,
      data: [
        {
          id: 1,
          target: 'todo',
          isBookmarked: true,
          title: 'Todo',
          icon: 'check-square',
          link: '/apps/todo'
        },
        {
          id: 2,
          target: 'calendar',
          isBookmarked: true,
          title: 'Calendar',
          icon: 'calendar',
          link: '/apps/calendar'
        }
      ]
    },
    {
      groupTitle: 'Files',
      searchLimit: 4,
      data: [
        {
          title: 'Passport Image',
          by: 'Oliver Queen',
          size: '52kb',
          file: 'assets/images/icons/jpg.png'
        },
        {
          title: 'Parenting Guide',
          by: 'Alfred Pennyworth',
          size: '2.3mb',
          file: 'assets/images/icons/doc.png'
        },
        {
          title: 'Class Notes',
          by: 'Barry Allen',
          size: '30kb',
          file: 'assets/images/icons/doc.png'
        },
        {
          title: 'Class Attendance',
          by: 'Walter White',
          size: '52mb',
          file: 'assets/images/icons/xls.png'
        }
      ]
    },
    {
      groupTitle: 'Contacts',
      searchLimit: 4,
      data: [
        {
          title: 'Mia Davis',
          email: 'miadavis@teleworm.us',
          img: 'assets/images/portrait/small/avatar-s-8.jpg',
          date: '01/03/2020'
        },
        {
          title: 'Norris Carrière',
          email: 'NorrisCarriere@rhyta.com',
          img: 'assets/images/portrait/small/avatar-s-3.jpg',
          date: '07/03/2020'
        },
        {
          title: 'Charlotte Gordon',
          email: 'CharlotteGordon@jourrapide.com',
          img: 'assets/images/portrait/small/avatar-s-26.jpg',
          date: '14/03/2020'
        },
        {
          title: 'Robert Nash',
          email: 'RobertNash@dayrep.com',
          img: 'assets/images/portrait/small/avatar-s-25.jpg',
          date: '21/03/2020'
        }
      ]
    }
  ];
}
