import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import {coreConfig} from '../../app-config';
import { NgxPermissionsService} from 'ngx-permissions';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param _ngXPermissionsServices
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _ngXPermissionsServices: NgxPermissionsService, private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/login`, { email, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('notifications', JSON.stringify(user.notifications));
            localStorage.setItem('profilePicture', user.avatar);
            this._ngXPermissionsServices.loadPermissions(user.permissions)
            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'You have successfully logged in as an ' +
                  user.role +
                  ' user to '+ coreConfig.app.appName +' . Now you can start to explore. Enjoy! 🎉',
                '👋 Welcome, ' + user.firstName + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }

    register(formData: FormData) {
      return this._http
          .post<any>(`${environment.apiUrl}/register`, formData)
          .pipe(
              map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                  // store user details and jwt token in local storage to keep user logged in between page refreshes
                  localStorage.setItem('currentUser', JSON.stringify(user));
                  localStorage.setItem('profilePicture', user.avatar);
                  this._ngXPermissionsServices.loadPermissions(user.permissions)
                  // Display welcome toast!
                  setTimeout(() => {
                    this._toastrService.success(
                        'You have successfully logged in as an ' +
                        user.role +
                        ' user to '+ coreConfig.app.appName +' . Now you can start to explore. Enjoy! 🎉',
                        '👋 Welcome, ' + user.firstName + '!',
                        { toastClass: 'toast ngx-toastr', closeButton: true }
                    );
                  }, 2500);

                  // notify
                  this.currentUserSubject.next(user);
                }

                return user;
              })
          );
    }

  confirmVerification(formData: FormData) {
    return this._http
        .post<any>(`${environment.apiUrl}/verify-account`, formData)
        .pipe(
            map(response => {

            })
        );
  }
}
