import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Appdata} from './lockminds/models/appdata';
import {AuthenticationService} from './auth/service';

@Injectable({
  providedIn: 'root'
})

export class AppService {

  public appData: Observable<Appdata>;
  private _appData: Appdata = new Appdata();

  private appDataSubject: BehaviorSubject<Appdata>;

  constructor(private _authenticationService: AuthenticationService) {
    this._initAppData();
  }

  private _initAppData(){
    this._appData.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.appDataSubject = new BehaviorSubject<Appdata>(this._appData) ;
    this.appData = this.appDataSubject.asObservable();
  }

  updateAppData(appdata: Appdata){
    this.appDataSubject = new BehaviorSubject<Appdata>(appdata);
  }

}
