import { Role } from './role';
import {Notifications} from './notifications';

export class User {
  id: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  avatar: string;
  role: Role;
  token?: string;
  permissions?: any;
  name?: string;
  cover_image?: string;
  notifications?: Notifications
}
