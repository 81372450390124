import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onNotificationsChange: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this.onNotificationsChange = new BehaviorSubject('');
    this.getNotifications();
  }

  getNotifications() {
    // this._httpClient.get(`${environment.apiUrl}/notifications/notifications`).subscribe( (response:any) => {
    //   this.onNotificationsChange.next(response)
    // })
  }

}
